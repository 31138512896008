body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Open Sans", "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
#root,
#root>section.ant-layout {
  height: 100%;
  // min-height: 1500px;
}

.ant-layout {
  background: none;
  ;
}

.ant-input,
.ant-select>div,
.ant-select-dropdown {
  border-radius: 0;
}

.ant-btn {
  border: none;
  box-shadow: none;
}

.ant-btn-primary {
  border-color: #bf914a !important;
  background-color: #bf914a !important;
  border-radius: 20px !important;
}


@import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css')
// @import '~slick-carousel/slick/slick';
// @import '~slick-carousel/slick/slick-theme';