.layout {
    .sub-layout {
        overflow: hidden;
        position: relative;

        &__content {
            width: calc(100% - 200px);
        }

        .page-title {
            font-family: "Open Sans";
            font-size: 1.7rem;
            font-weight: 400;
            padding-bottom: 20px;
            padding-left: 10px;
        }

        .row-odd {
            background-color: transparent;
        }

        .row-even {
            background-color: #FAFAFA;
        }
    }

    
}