.contact-us-container{
  font-family: Montserrat;
  padding :20px;

  .contact-us-table-content {
    padding-top: 25px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  }

  .contact-us-header{
    display: flex;
    justify-content: space-between;
    h1{
      font-size: 28px;
      line-height: 38px;
      color: #303030;
    }

    .extract-btn {
      background: #BF914A !important;
      border-radius: 46px;
      border: 0;
      width:150px;
      height:40px;
    }
  }

  .contact-us-filter {
    
    h1{
      font-size: 12px;
      line-height: 16px;
      margin:20px 0;
      color: #6A6A6A;
      text-transform: uppercase;
      font-weight: 400;
    }

    .filter-radio {
      padding-bottom: 14px;
      .radio {
        display: inline-block;
        position: relative;
        padding-left: 50px;
        padding-top: 4px;
        margin-bottom: 12px;
        margin-right: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
  
      /* Hide the browser's default checkbox */
      .radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
  
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid #1F2D3D;
      }
  
      /* On mouse-over, add a grey background color */
      .radio:hover input ~ .checkmark {
        background-color: #ccc;
      }
  
      /* When the checkbox is checked, add a blue background */
      .radio input:checked ~ .checkmark {
        background-color: #1F2D3D;
      }
  
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
  
      /* Show the checkmark when checked */
      .radio input:checked ~ .checkmark:after {
        display: block;
      }
  
      /* Style the checkmark/indicator */
      .radio .checkmark:after {
        left: 12px;
        top: 7px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

    }

    .contact-search {
      width:100%;

      .search-container {
        margin: 8%;
      }
  
      .contact-sort-by {
         span {
           margin-left: 10px;
           margin-right: 10px;
         }
         .contact-sort-by-input {
          width: 200px;
         }

         .filter-by-container {
           margin: 4%;
         }
      }
    }
    
    
  }


  .contact-us-table {
    padding: 20px 0;
    overflow-x:auto;
  }

  .hidden{
    display: none;
  }

  
  .ant-input-number{
    border: 0 !important;
    border-bottom:2px solid #DFC8A5 !important;
    background: transparent !important;

    // &:active{
    //   border: 0 !important;
    //   border-bottom:2px solid #DFC8A5 !important;
    //   background: transparent !important;
    //   outline: 0 !important;
    //   box-shadow: none !important;
    //   border-color: 0 !important;
    // }

    // &:focus{
    //   border: 0 !important;
    //   border-bottom:2px solid #DFC8A5 !important;
    //   background: transparent !important;
    //   outline: 0 !important;
    //   box-shadow: none !important;
    //   border-color: 0 !important;
    // }

    .ant-input-number-input-wrap:focus{
      outline:0 !important;
    }

    .ant-input-number-input-wrap:active{
      outline:0 !important;
    }
  }

}


.contact-tag-existing{
  background-color: #AF7640 !important;
  border-radius: 10px !important;
  color:white !important;
}

.contact-tag-new {
  background-color: #6BBF59 !important;
  border-radius: 10px !important;
  color:white !important;
} 

