.ant-layout-header{
		background-color: #FFFFFF;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		z-index: 1;
    .logo{
			width: 120px;
			height: 38px;
			background-image: url('../../../assets/images/logo.png');
			background-repeat: no-repeat;
			background-size: auto 100%;
			margin: 0.8rem 1.5rem 0.8rem 0;
			float: left;
    }
    .ant-menu-urc{
			box-shadow: none;
			border-bottom-width: 0;
			.ant-menu-item{
				text-transform: uppercase;
				&.ant-menu-item-selected{
					background-color: #f4d9b3;
					border-bottom-color: transparent;
					a{
						color: #6f3c1e;
						font-weight: bold;
					}
				}
				a{
					cursor: pointer;
					text-align: center;
					&::after {
						display: block;
						content: attr(data-menu-label);
						font-weight: bold;
						height: 1px;
						color: transparent;
						overflow: hidden;
						visibility: hidden;
					}
				}
				&.ant-menu-item-active{
					border-bottom-color: #f4d9b3;
					a{
						color: #6f3c1e;
					}
				}
			}
			&.right{
				.ant-menu-item{
					border-bottom-color: transparent;
					&.search{
						i{
							font-size: 1.4rem;
							margin: 0;
							vertical-align: middle;
						}
					}
					&.login-signup{
						background-color: #df0313;
						a{
							color: #FFFFFF;
						}
					}
				}
			}
    }
}