.admin-dashboard-page {
    margin: 25px 0px;

    .filters {
        .date {
          display: flex;
          flex: 1;
          justify-content: flex-start;
            p {
                font-size: 16px !important;
                color: #6A6A6A;
                span {
                    color: #404040;
                }
            }
        }

        p {
            text-transform: uppercase;
            margin-bottom: 8px;
            font-size: 12px;
        }
    }

    .ant-card {
        border-radius: 6px;
        border: 0;
    }


    .ant-card-bordered {
        box-shadow: 0px 7px 32px rgba(0, 0, 0, 0.07);
    }

    .ant-card-head-title {
      padding-bottom: 8px;
    }

    .ant-card-head {
        border-bottom: none; 
        color: #898989;
        font-weight: 600;
        font-size: 14px;
    }

    .order-placement-card {
        min-height: 393px;
        .ant-card-head-title {
          span {
            color: #404040;
          }
        }
        .ant-card-body {
            padding-top: 0;
        }
        p {
            color: #B0B0B0;
            font-weight: 600;
        }
        .ant-badge-status-dot {
            width: 12px;
            height: 12px;
            border-radius: 0;
        }
    }

    

    .summary-card {
        margin-top: 20px;
        
        .ant-card-body {
            padding: 0;
        }

        .ant-col {
            // text-align: center;
            border-right: 1px solid #e8e8e8;
            // padding: 30px 15px;
            // align-items: center;

            p:nth-child(1) {
                padding-top: 20px;
                font-family: Montserrat;
                color: #404040;
                font-size: 24px;
            }
            p:nth-child(2) {
                    color: #59BFB9;
                    font-weight: 600;
                    font-size: 14px;
            }

            span {
                font-weight: 600;
               color: #9F9F9F;
            }
        }

        p {
            margin-bottom: 0 !important;
        }
    }


    .ant-btn{
        border-radius: 46px;
        font-weight: 600;
        box-shadow: none;
      }
      .btn-main {
        height: 50px;
        min-width: 172px;
        font-size: 18px;
        line-height: 25px;
      }
  
      .btn-small {
        height: 42px;
        min-width: 150px;
        font-size: 16px;
        line-height: 22px;
      }
  
      .btn-default {
        color: #848484;
        border: none;
        &:hover {
          color: #848484;
          border: none;
          background: transparent;
        }
        &:disabled, &:disabled:hover {
          background: transparent;
        }
      }
  
      .btn-secondary {
        color: #2298E7;
        border: 1px solid #2298E7; 
        &:hover {
          color: #2298E7;
          border: 1px solid #2298E7; 
          background: transparent;
        }
        &:disabled, &:disabled:hover {
          background: transparent;
        }
      }
  
      .btn-primary {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;
        &:hover, &:active, &:focus {
          background-color: #bf914a;
          border-color: #bf914a;
          color: #FFFFFF;
  
        }
        &:disabled, &:disabled:hover {
          background-color: #E5D3B6;
          border-color: #E5D3B6;
          color: #FFFFFF;
        }
      }
}

.modal {
    p {
        color: #000000;
        font-size: 16px;
    }

    .ant-checkbox-group {
      margin-left: 10px;
    }
    .ant-btn{
        border-radius: 46px;
        font-weight: 600;
        box-shadow: none;
      }
      .ant-btn-link {
        color: #7A4521;
        padding: 0;
        font-weight: 500;
      }

      .btn-small {
        height: 42px;
        min-width: 150px;
        font-size: 16px;
        line-height: 22px;
      }

      .btn-default {
        color: #848484;
        border: none;
        &:hover {
          color: #848484;
          border: none;
          background: transparent;
        }
        &:disabled, &:disabled:hover {
          background: transparent;
        }
      }

      .btn-primary {
        background-color: #bf914a;
        border-color: #bf914a;
        color: #FFFFFF;
        &:hover, &:active, &:focus {
          background-color: #bf914a;
          border-color: #bf914a;
          color: #FFFFFF;
  
        }
        &:disabled, &:disabled:hover {
          background-color: #E5D3B6;
          border-color: #E5D3B6;
          color: #FFFFFF;
        }
      }
    .ant-checkbox-group-item {
        display: block;
        margin: 15px 0px;
    }

}

